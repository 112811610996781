import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/ElectoralLiteracy/Workshop/1.jpg';
import p2 from 'assests/Photos/ElectoralLiteracy/Workshop/2.jpg';
import p3 from 'assests/Photos/ElectoralLiteracy/Workshop/3.jpg';
import p4 from 'assests/Photos/ElectoralLiteracy/Workshop/4.jpg';
import p5 from 'assests/Photos/ElectoralLiteracy/Workshop/5.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/Literaryclub2023/components';
import { Sidebar } from './components';

const ElectoralLiteracy2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        }, 
        {
          src: p5,
          source: p5,
          rows: 1,
          cols: 1,
      },
        
        
    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                Workshop on the Electoral Process
                                </Typography>
                              
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class:6 to 8   &  Date:15 JULY 2023              
                                </Typography>
                                <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                              ><br />
                                "Voting is the expression of our commitment to ourselves, one another, this country and this world.
                                 – Sharon Salzberg"
                                <br />
                            </Typography>
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The Electoral Literacy Club of National Public School, Yeshwanthpur organized WORKSHOP ON THE ELECTORAL PROCESS on 15 July 2023 for the students of classes 6 to 8.  Democracy is based upon the conviction that there are extraordinary possibilities in ordinary people. Elections give voice to people, to express their opinion and to choose the person whose beliefs match with theirs. Civic education is necessary to ensure that all citizens understand their rights, their political system and how and where to vote.
                                <br />
                                The activity aimed to raise awareness among the young learners  about the electoral process and the importance of voting. It was followed by an interactive session where students clarified their queries on the electoral process. 
                                <br />
                                It was truly an informative and enriching experience for the students.
                                </Typography>
                                <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                              ><br />
                                "THERE'S NO SUCH THING AS A VOTE THAT DOESN'T MATTER. <br/>
 - BARACK OBAMA
"
                                <br />
                            </Typography>
                            </Box>
                            
                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 500}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        {/* <Box marginBottom={4}>
                                    <Sidebar2023/>
                        </Box> */}
                        <Box marginBottom={4}>
                            <Sidebar/>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default ElectoralLiteracy2023;